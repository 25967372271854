import AOS from 'aos'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import Chart from "react-apexcharts"
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import CountUp from 'react-countup'
import { connect } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import { bindActionCreators } from "redux"
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import '../../../node_modules/aos/dist/aos'
import '../../../node_modules/aos/dist/aos.css'
import '../../../node_modules/swiper/modules/navigation/navigation.scss'
import '../../assets/css/index.css'
import shapes1 from '../../assets/images/shapes/01.png'
import shapes2 from '../../assets/images/shapes/02.png'
import shapes3 from '../../assets/images/shapes/03.png'
import shapes4 from '../../assets/images/shapes/04.png'
import shapes5 from '../../assets/images/shapes/05.png'
import UnpaidLeave from '../../assets/images/shapes/paid-leave.png'
import Circularprogressbar from '../../components/circularprogressbar.js'
import { PermissionData } from '../../layouts/dashboard/default.js'
import { ColorCustomizerAction, ModeAction, NavbarstyleAction, SchemeDirAction, SidebarActiveStyleAction, SidebarColorAction, getDarkMode, getDirMode, getNavbarStyleMode, getSidebarActiveMode, getSidebarColorMode, getSidebarTypeMode, getcustomizerMode, getcustomizerinfoMode, getcustomizerprimaryMode } from '../../store/setting/setting'
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})

const Index = (props) => {
    const [Employee, setEmployee] = useState([]);
    const [workUserId, setWorkUserId] = useState('');
    const [pendingEmployee, setPendingEmployee] = useState([]);
    const [ExEmployee, setExEmployee] = useState([]);
    const [activeEmployee, setActiveEmployee] = useState([]);
    const [task, setTask] = useState([]);
    const [projects, setProjects] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [employeeLeavesData, setEmployeeLeavesData] = useState([]);
    const [remainingLeaves, setRemainingLeaves] = useState([]);
    const [holidayData, setholidayData] = useState([]);
    const [weekHours, setWeekHours] = useState([]);
    const [weekBrealHours, setWeekBreakHours] = useState([]);
    const [aadharShow, setAadharShow] = useState(false);
    const [totalAverage, setTotalAverage] = useState(null);
    const navigate = useNavigate();
    const Permissions = useContext(PermissionData);

    useEffect(() => {
        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);
        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }
    })

    const chart2 = {
        options: {
            colors: [props.colorprimarymode, props.cololrinfomode],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 10,
                        size: "50%",
                    },
                    track: {
                        margin: 10,
                        strokeWidth: '50%',
                    },
                    dataLabels: {
                        show: false,
                    }
                }
            },
            labels: ['Apples', 'Oranges'],
        },
        series: [55, 75],
    }

    const api = props.api;
    const user_data = props.user_data;
    const shapes = [shapes1, shapes2, shapes3, shapes4, shapes5]

    useEffect(() => {
        setWorkUserId(user_data._id)
        return () => { };
    }, [user_data._id]);

    const IndexData = async () => {
        await api.get(`/index`).then((response) => {
            setEmployee(response.data.userData)
            setTotalAverage(response.data.totalAverage)
            setActiveEmployee(response.data.userActive)
            setPendingEmployee(response.data.userPending)
            setExEmployee(response.data.ExEmployee)
            setTask(response.data.pendingTaskData)
            setProjects(response.data.allProjectData)
            setAllProjects(response.data.projectData)
            setLeaves(response.data.leavesrequestData)
            setEmployeeLeavesData(response.data.leaveHistoryData);
            setRemainingLeaves(response.data.leaveHistoryData?.remaining_leaves)
            setholidayData(response.data.dataholiday)
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const WorkingDetail = async () => {
        await api.post(`/indexWorkingHour`, {
            user_id: workUserId ? workUserId : user_data._id
        }).then((response) => {
            setWeekHours(response.data.weeklyHours)
            setWeekBreakHours(response.data.breakHours)
        }).catch((error) => {
            console.log("error", error);
        })
    }

    useEffect(() => {
        WorkingDetail()
        IndexData();
    }, [workUserId]);


    const GraphEmployee = [pendingEmployee, activeEmployee];

    const handleMouseDown = () => {
        setAadharShow(true);
    }

    const handleMouseUp = () => {
        setAadharShow(false);
    }

    const chart3 = {
        options: {
            chart: {
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            colors: [props.colorprimarymode, props.cololrinfomode],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '28%',
                    endingShape: 'rounded',
                    borderRadius: 5,
                },
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['M', 'T', 'W', 'T', 'F', 'S'],
                labels: {
                    minHeight: 20,
                    maxHeight: 20,
                    style: {
                        colors: "#8A92A6",
                    },
                }
            },
            yaxis: {
                max: 12,
                tickAmount: 4,
                tickInterval: 3,
                labels: {
                    style: {
                        colors: "#8A92A6"
                    }
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        var parts = val.toString().split('.');
                        if (parts?.length > 1) {
                            if ((parts[1] < 9) && (parts[1] % 1 === 0) && (!parts[1].includes(0))) {
                                return parts[0] + "H" + ' ' + parts[1] * 10 + 'M'
                            }
                            else {
                                return parts[0] + "H" + ' ' + parts[1] + 'M'
                            }
                        }
                        return parts[0] + "H"
                    }
                }
            }
        },

        series: [{
            name: 'Break Hours',
            data: weekBrealHours && weekBrealHours
        }, {
            name: 'Working Hours',
            data: weekHours && weekHours
        }
        ]
    }

    return (
        <>
            <Row>
                <Col md="12" lg="12">
                    <Row className="row-cols-1">
                        <div className="overflow-hidden d-slider1 ">
                            <Swiper className="p-0 m-0 mb-2 list-inline "
                                slidesPerView={5}
                                spaceBetween={32}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev'
                                }}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    550: { slidesPerView: 2 },
                                    991: { slidesPerView: 3 },
                                    1400: { slidesPerView: 4 },
                                    1500: { slidesPerView: 5 },
                                    1920: { slidesPerView: 6 },
                                    2040: { slidesPerView: 7 },
                                    2440: { slidesPerView: 8 }
                                }} data-aos="fade-up" data-aos-delay="700"
                            >
                                {Permissions.includes('View Employees') &&
                                    <SwiperSlide className="card card-slide" style={{ cursor: 'pointer' }} onClick={() => navigate('/employee')}>
                                        <div className="card-body">
                                            <div className="progress-widget" >
                                                <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{ width: 60, height: 60, }} value={Employee?.length} id="circle-progress-01" >
                                                    <svg className="" width="28" height="24px" viewBox="0 0 24 24">
                                                        <path d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z" fill="currentColor"></path>
                                                        <path opacity="0.4" d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z" fill="currentColor"></path>
                                                        <path opacity="0.4" d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z" fill="currentColor"></path>
                                                        <path d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z" fill="currentColor"></path>
                                                        <path opacity="0.4" d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z" fill="currentColor"></path>
                                                        <path d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z" fill="currentColor"></path>
                                                    </svg>
                                                </Circularprogressbar>
                                                <div className="progress-detail">
                                                    <p className="mb-2">Employees</p>
                                                    <h4 className="counter">
                                                        <CountUp start={0} end={Employee?.length} duration={2} />
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }
                                <SwiperSlide className=" card card-slide" style={{ cursor: 'pointer' }} onClick={() => navigate('/project')}>
                                    <div className="card-body">
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={projects?.length} id="circle-progress-02" >
                                                <svg width="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z" fill="currentColor" />
                                                    <path d="M7.36866 9.3689C6.91533 9.3689 6.54199 9.74223 6.54199 10.2045V17.0756C6.54199 17.5289 6.91533 17.9022 7.36866 17.9022C7.83088 17.9022 8.20421 17.5289 8.20421 17.0756V10.2045C8.20421 9.74223 7.83088 9.3689 7.36866 9.3689Z" fill="currentColor" />
                                                    <path d="M12.0352 6.08887C11.5818 6.08887 11.2085 6.4622 11.2085 6.92442V17.0755C11.2085 17.5289 11.5818 17.9022 12.0352 17.9022C12.4974 17.9022 12.8707 17.5289 12.8707 17.0755V6.92442C12.8707 6.4622 12.4974 6.08887 12.0352 6.08887Z" fill="currentColor" />
                                                    <path d="M16.6398 12.9956C16.1775 12.9956 15.8042 13.3689 15.8042 13.8312V17.0756C15.8042 17.5289 16.1775 17.9023 16.6309 17.9023C17.0931 17.9023 17.4664 17.5289 17.4664 17.0756V13.8312C17.4664 13.3689 17.0931 12.9956 16.6398 12.9956Z" fill="currentColor" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <p className="mb-2">Total Project</p>
                                                <h4 className="counter"><CountUp start={0} end={allProjects} duration={2} /></h4>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* <SwiperSlide className=" card card-slide" style={{ cursor: 'pointer' }} onClick={() => navigate('/task')}>
                                    <div className="card-body">
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' Linecap='rounded' strokewidth="4px" value={totalAverage} style={{ width: 60, height: 60, }} id="circle-progress-06">
                                                <svg width="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z" fill="currentColor" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z" fill="currentColor" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <p className="mb-2">Avg hours</p>
                                                <h4 className="counter"><CountUp start={0} end={totalAverage} duration={2} /></h4>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                                {Permissions.includes('View Leaves Request') &&
                                    <SwiperSlide className=" card card-slide" style={{ cursor: 'pointer' }} onClick={() => navigate('/all-requests')}>
                                        <div className="card-body">
                                            <div className="progress-widget">
                                                <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={leaves} id="circle-progress-03" >
                                                    <svg width="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.4" d="M7.29639 6.446C7.29639 3.995 9.35618 2 11.8878 2H16.9201C19.4456 2 21.5002 3.99 21.5002 6.436V17.552C21.5002 20.004 19.4414 22 16.9098 22H11.8775C9.35205 22 7.29639 20.009 7.29639 17.562V16.622V6.446Z" fill="currentColor" />
                                                        <path d="M16.0374 11.4538L13.0695 8.54482C12.7627 8.24482 12.2691 8.24482 11.9634 8.54682C11.6587 8.84882 11.6597 9.33582 11.9654 9.63582L13.5905 11.2288H3.2821C2.85042 11.2288 2.5 11.5738 2.5 11.9998C2.5 12.4248 2.85042 12.7688 3.2821 12.7688H13.5905L11.9654 14.3628C11.6597 14.6628 11.6587 15.1498 11.9634 15.4518C12.1168 15.6028 12.3168 15.6788 12.518 15.6788C12.717 15.6788 12.9171 15.6028 13.0695 15.4538L16.0374 12.5448C16.1847 12.3998 16.268 12.2038 16.268 11.9998C16.268 11.7948 16.1847 11.5988 16.0374 11.4538Z" fill="currentColor" />
                                                    </svg>
                                                </Circularprogressbar>
                                                <div className="progress-detail">
                                                    <p className="mb-2">Leave Req</p>
                                                    <h4 className="counter"><CountUp start={0} end={leaves} duration={2} /></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }
                                {Permissions.includes('View Holidays') &&
                                    <SwiperSlide className=" card card-slide" style={{ cursor: 'pointer' }} onClick={() => navigate('/holiday')}>
                                        <div className="card-body">
                                            <div className="progress-widget">
                                                <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={holidayData?.length} id="circle-progress-04" >
                                                    <svg width="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 16.8701V9.25708H21V16.9311C21 20.0701 19.0241 22.0001 15.8628 22.0001H8.12733C4.99561 22.0001 3 20.0301 3 16.8701ZM7.95938 14.4101C7.50494 14.4311 7.12953 14.0701 7.10977 13.6111C7.10977 13.1511 7.46542 12.7711 7.91987 12.7501C8.36443 12.7501 8.72997 13.1011 8.73985 13.5501C8.7596 14.0111 8.40395 14.3911 7.95938 14.4101ZM12.0198 14.4101C11.5653 14.4311 11.1899 14.0701 11.1701 13.6111C11.1701 13.1511 11.5258 12.7711 11.9802 12.7501C12.4248 12.7501 12.7903 13.1011 12.8002 13.5501C12.82 14.0111 12.4643 14.3911 12.0198 14.4101ZM16.0505 18.0901C15.596 18.0801 15.2305 17.7001 15.2305 17.2401C15.2206 16.7801 15.5862 16.4011 16.0406 16.3911H16.0505C16.5148 16.3911 16.8902 16.7711 16.8902 17.2401C16.8902 17.7101 16.5148 18.0901 16.0505 18.0901ZM11.1701 17.2401C11.1899 17.7001 11.5653 18.0611 12.0198 18.0401C12.4643 18.0211 12.82 17.6411 12.8002 17.1811C12.7903 16.7311 12.4248 16.3801 11.9802 16.3801C11.5258 16.4011 11.1701 16.7801 11.1701 17.2401ZM7.09989 17.2401C7.11965 17.7001 7.49506 18.0611 7.94951 18.0401C8.39407 18.0211 8.74973 17.6411 8.72997 17.1811C8.72009 16.7311 8.35456 16.3801 7.90999 16.3801C7.45554 16.4011 7.09989 16.7801 7.09989 17.2401ZM15.2404 13.6011C15.2404 13.1411 15.596 12.7711 16.0505 12.7611C16.4951 12.7611 16.8507 13.1201 16.8705 13.5611C16.8804 14.0211 16.5247 14.4011 16.0801 14.4101C15.6257 14.4201 15.2503 14.0701 15.2404 13.6111V13.6011Z" fill="currentColor" />
                                                        <path opacity="0.4" d="M3.00293 9.25699C3.01577 8.66999 3.06517 7.50499 3.15803 7.12999C3.63224 5.02099 5.24256 3.68099 7.54442 3.48999H16.4555C18.7376 3.69099 20.3677 5.03999 20.8419 7.12999C20.9338 7.49499 20.9832 8.66899 20.996 9.25699H3.00293Z" fill="currentColor" />
                                                        <path d="M8.30465 6.59C8.73934 6.59 9.06535 6.261 9.06535 5.82V2.771C9.06535 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z" fill="currentColor" />
                                                        <path d="M15.6953 6.59C16.1201 6.59 16.456 6.261 16.456 5.82V2.771C16.456 2.33 16.1201 2 15.6953 2C15.2606 2 14.9346 2.33 14.9346 2.771V5.82C14.9346 6.261 15.2606 6.59 15.6953 6.59Z" fill="currentColor" />
                                                    </svg>
                                                </Circularprogressbar>
                                                <div className="progress-detail">
                                                    <p className="mb-2">Holidays</p>
                                                    <h4 className="counter"><CountUp start={0} end={holidayData?.length} duration={2} /></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }
                                {Permissions.includes('View Tasks') &&
                                    <SwiperSlide className=" card card-slide" style={{ cursor: 'pointer' }} onClick={() => navigate('/task')}>
                                        <div className="card-body">
                                            <div className="progress-widget">
                                                <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' Linecap='rounded' strokewidth="4px" value={task} style={{ width: 60, height: 60, }} id="circle-progress-06">
                                                    <svg width="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.4" d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z" fill="currentColor" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z" fill="currentColor" />
                                                    </svg>
                                                </Circularprogressbar>
                                                <div className="progress-detail">
                                                    <p className="mb-2">Tasks</p>
                                                    <h4 className="counter"><CountUp start={0} end={task} duration={2} /></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }

                                <div className="swiper-button swiper-button-next"></div>
                                <div className="swiper-button swiper-button-prev"></div>
                            </Swiper>
                        </div>
                    </Row>
                </Col>
                <Col md="12" lg="8">
                    <Row>
                        {Permissions.includes('View Employees') &&
                            <Col md="12" xl="6">
                                <div className="card" data-aos="fade-up" data-aos-delay="900">
                                    <div className="flex-wrap card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Employees</h4>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="flex-wrap d-flex align-items-center justify-content-between">
                                            <Chart className="col-md-8 col-lg-8" options={chart2.options} series={GraphEmployee} type="radialBar" height="250" />
                                            <div className="d-grid gap col-md-4 col-lg-4">
                                                <div className="d-flex align-items-start">
                                                    <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#3a57e8">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-3">
                                                        <span className="text-secondary">ExEmployee</span>
                                                        <h6>{ExEmployee ? ExEmployee : 0}</h6>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-start">
                                                    <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#3a57e8">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-3">
                                                        <span className="text-secondary">Pending </span>
                                                        <h6>{pendingEmployee}</h6>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-start">
                                                    <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#4bc7d2">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="#4bc7d2"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-3">
                                                        <span className="text-secondary">Active </span>
                                                        <h6>{activeEmployee}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        }
                        {!Permissions.includes('View Employees') &&
                            <Col md="12" xl='6'>
                                <div className="card" data-aos="fade-up" data-aos-delay="600">
                                    <div className=" flex-wrap card-header d-flex justify-content-between">
                                        <div className="col-lg-6 header-title">
                                            <h4 className="mb-2 card-title">Holiday overview</h4>
                                            <p className="mb-0">
                                                <svg style={{ transform: 'rotate(180deg)' }} className="me-2" width="24" height="24" viewBox="0 0 24 24">
                                                    <path fill="#17904b" d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" />
                                                </svg>
                                                Upcoming Holidays
                                            </p>
                                        </div>
                                        <div className="col-lg-6 header-title d-flex align-items-center justify-content-end">
                                            <Link to='/holiday' className='btn btn-link btn-soft-primary'>
                                                <span className='text-primary'>View Holidays</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {holidayData?.length > 0 ?
                                            holidayData.map((item, index) => (
                                                index < 3 ?
                                                    <div className="mb-2 d-flex profile-media align-items-top" key={index}>
                                                        <div className="mt-1 profile-dots-pills border-primary"></div>
                                                        <div className="ms-4">
                                                            <h6 className="mb-1 ">{item.holiday_name}</h6>
                                                            <span className="mb-0">{moment(item.holiday_date).format('ll')}</span>
                                                        </div>
                                                    </div> : ''
                                            )) :
                                            <div className="mb-2 d-flex profile-media align-items-top">
                                                <div className="mt-1 profile-dots-pills border-primary"></div>
                                                <div className="ms-4">
                                                    <h6 className="mb-1 ">There is no Upcoming Holidays</h6>
                                                    <span className="mb-0"></span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        }
                        <Col md="12" xl="6">
                            <div className="card" data-aos="fade-up" data-aos-delay="1000">
                                <div className="flex-wrap card-header d-flex justify-content-between">
                                    <div className="row header-title">
                                        <div className={`col-md-6`}>
                                            <h4 className="card-title">Working Hour Overview</h4>
                                        </div>
                                        <div className="col-md-6">
                                            {user_data.roleData[0].role_name === 'Admin' ?
                                                <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                                    <select
                                                        name="user-list-table_length" aria-controls="user-list-table"
                                                        className="form-select form-select-sm"
                                                        onChange={(e) => setWorkUserId(e.target.value)}
                                                        value={workUserId}
                                                    >
                                                        <option value="" disabled>select</option>
                                                        {Employee && Employee.map((item, index) =>
                                                            <option value={item._id} key={index}>{item.firstname + ' ' + item.last_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                :
                                                <div className=" header-title d-flex align-items-center justify-content-end">
                                                    <Link to='/working-hour' className='btn btn-link btn-soft-info'>
                                                        <span className='text-info'>View Hours</span>
                                                    </Link>
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart className="d-activity" options={chart3.options} series={chart3.series} type="bar" height="230" />
                                </div>
                            </div>
                        </Col>
                        <Col md="12" lg="12">
                            <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                <div className="row flex-wrap card-header d-flex justify-content-between">
                                    <div className="col-lg-6 header-title">
                                        <h4 className="mb-2 card-title">Project OverView</h4>
                                    </div>
                                    <div className="col-lg-6 header-title d-flex align-items-center justify-content-end">
                                        <Link to='/project' className='btn btn-link btn-soft-info'>
                                            <span className='text-info'>View Details</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="mt-4 table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <thead>
                                                <tr>
                                                    <th>PROJECTS</th>
                                                    <th>EMPLOYEES</th>
                                                    <th>STATUS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {projects !== undefined ? projects.map((item, index) => (
                                                    index < 4 ?
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded bg-soft-primary img-fluid avatar-40 me-3" src={shapes[index]} alt="profile" />
                                                                    <h6>{item.title}</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="iq-media-group iq-media-group-1">
                                                                    {item.userData && item.userData.map((data, index) => {
                                                                        return (
                                                                            <OverlayTrigger placement="bottom" overlay={
                                                                                <Tooltip >
                                                                                    {data.firstname + ' ' + data.last_name}
                                                                                </Tooltip>
                                                                            } key={index}>
                                                                                <Link to="#" className="iq-media-1" >
                                                                                    <div className="icon iq-icon-box-3 rounded-pill d-flex justify-content-center align-items-center" style={{ textTransform: 'uppercase' }} key={index}>
                                                                                        {data.firstname.charAt(0)}{data.last_name.charAt(0)}
                                                                                    </div>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className={`col-lg-6 header-title d-flex align-items-center justify-content-center w-50 `}
                                                                    style={{ border: item.status === 'on Hold' ? '1px solid #4bc7d2' : item.status === 'in Progress' ? '1px solid #f16a1b ' : '1px solid #1aa053', borderRadius: '3px' }}
                                                                >
                                                                    <span className={`text-${item.status === 'on Hold' ? 'info' : item.status === 'in Progress' ? 'warning' : 'success'} py-1 px-5`}
                                                                    >{item.status}</span>
                                                                </div>
                                                            </td>
                                                        </tr> : ''
                                                )) :
                                                    <tr>No data found</tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md="12" lg="4">
                    <Row>
                        <Col md="12" lg="12">
                            <div className="card credit-card-widget" data-aos="fade-up" data-aos-delay="900">
                                <div className="pb-4 border-0 card-header">
                                    <div className="p-4 rounded primary-gradient-card">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className="font-weight-bold">Employee Card </h5>
                                                <p className="mb-0">{user_data.emp_code}</p>
                                            </div>
                                            <div className="master-card-content">
                                                <svg className="master-card-1" width="60" height="60" viewBox="0 0 24 24">
                                                    <path fill="#ffffff" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                                </svg>
                                                <svg className="master-card-2" width="60" height="60" viewBox="0 0 24 24">
                                                    <path fill="#ffffff" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="my-4 d-flex align-items-center">
                                            <div className="card-number">
                                                {aadharShow === false ?
                                                    <>
                                                        <span className="fs-5 me-2">XXXX</span>
                                                        <span className="fs-5 me-2">XXXX</span>
                                                    </> :
                                                    <>
                                                        <span className="fs-5 me-2">{user_data.aadhar_number.slice(0, 4)}</span>
                                                        <span className="fs-5 me-2">{user_data.aadhar_number.slice(4, 8)}</span>
                                                    </>
                                                }
                                                <span className="fs-5 me-2">{user_data.aadhar_number ? user_data.aadhar_number.slice(8, 12) : 'XXXX'}</span>
                                            </div>
                                            {user_data.aadhar_number &&
                                                <div className="show-hide-aadhar"
                                                    onMouseDown={handleMouseDown}
                                                    onMouseUp={handleMouseUp} style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-2 d-flex align-items-center justify-content-between">
                                            <p className="mb-0">
                                                Card holder
                                            </p>
                                            <p className="mb-0">Joining Date</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6>{user_data.firstname + ' ' + user_data.last_name}</h6>
                                            <h6 className="ms-5">{moment(user_data.doj).format('MM') + '/' + moment(user_data.doj).format('YY')}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card credit-card-widget" data-aos="fade-up" data-aos-delay="900">
                                <div className="card-body">
                                    <div className="flex-wrap mb-4 d-flex align-items-center">
                                        <div className="d-flex align-items-center me-0 me-md-5">
                                            <div>
                                                <div className="p-3 mb-2 rounded bg-soft-primary">
                                                    <svg width="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856ZM20.4933 4.89833C21.3244 4.89833 22 5.56203 22 6.37856C22 7.19618 21.3244 7.85989 20.4933 7.85989H13.9178C13.0856 7.85989 12.4101 7.19618 12.4101 6.37856C12.4101 5.56203 13.0856 4.89833 13.9178 4.89833H20.4933ZM3.50777 15.958H10.0833C10.9155 15.958 11.5911 16.6217 11.5911 17.4393C11.5911 18.2558 10.9155 18.9206 10.0833 18.9206H3.50777C2.67555 18.9206 2 18.2558 2 17.4393C2 16.6217 2.67555 15.958 3.50777 15.958ZM18.5611 20.7778C20.4611 20.7778 22 19.2648 22 17.3992C22 15.5325 20.4611 14.0196 18.5611 14.0196C16.6623 14.0196 15.1223 15.5325 15.1223 17.3992C15.1223 19.2648 16.6623 20.7778 18.5611 20.7778Z" fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <h5>{employeeLeavesData?.taken_leaves !== undefined ? employeeLeavesData?.taken_leaves : ''}</h5>
                                                <small className="mb-0">Taken</small>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center ms-2">
                                            <div>
                                                <div className="p-3 mb-2 rounded bg-soft-info">
                                                    <svg width="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.3264 2.20966C12.4861 2.06632 12.6973 1.99119 12.9135 2.00082C17.4843 2.13765 21.3044 5.4558 21.9967 9.89063C22.0011 9.91711 22.0011 9.94411 21.9967 9.97059C22.0116 10.1804 21.9407 10.3874 21.7996 10.5458C21.6586 10.7043 21.459 10.801 21.2451 10.8147L13.5656 11.3211C13.3116 11.3436 13.0597 11.26 12.8718 11.0909C12.6839 10.9218 12.5774 10.6828 12.5785 10.4326L12.0623 2.88932V2.76493C12.0717 2.55278 12.1667 2.353 12.3264 2.20966ZM11.7997 13.2936L18.4558 12.8671L18.5011 12.8848C18.7869 12.8895 19.0591 13.0054 19.2579 13.207C19.4566 13.4087 19.5655 13.6795 19.5606 13.9599C19.2984 17.782 16.4962 20.9755 12.6828 21.7982C8.86938 22.621 4.96017 20.8754 3.08778 17.5139C2.53722 16.5457 2.1893 15.4794 2.06445 14.3775C2.01603 14.051 1.99483 13.7212 2.00106 13.3913C2.01368 9.32706 4.90728 5.81907 8.95607 4.9595C9.4462 4.86776 9.93762 5.11248 10.1515 5.55479C10.2047 5.63505 10.2473 5.72164 10.2782 5.81245C10.3541 6.98405 10.4329 8.14455 10.5113 9.30015C10.5732 10.2128 10.6349 11.1223 10.6948 12.0319C10.6917 12.2462 10.7254 12.4594 10.7944 12.6627C10.9569 13.0627 11.3614 13.3165 11.7997 13.2936Z" fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <h5>{employeeLeavesData?.remaining_leaves !== undefined ? employeeLeavesData.remaining_leaves : ''}</h5>
                                                <small className="mb-0">Remaining</small>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center me-0 me-md-4">
                                            <div>
                                                <div className="p-3 mb-2 rounded bg-soft-primary">
                                                    <i className="icon">
                                                        <img src={UnpaidLeave} alt='UnPaid Leave' width={30} height={30} />
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <h5>{employeeLeavesData?.taken_leaves !== undefined ? employeeLeavesData?.taken_leaves - employeeLeavesData.unpaid_leaves : ''}</h5>
                                                <small className="mb-0">Paid Leave</small>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center me-0 me-md-4">
                                            <div>
                                                <div className="p-3 mb-2 rounded bg-soft-primary">
                                                    <i className="icon">
                                                        <img src={UnpaidLeave} alt='UnPaid Leave' width={30} height={30} />
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <h5>{employeeLeavesData?.unpaid_leaves !== undefined ? employeeLeavesData.unpaid_leaves : ''}</h5>
                                                <small className="mb-0">Un-Paid Leave</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex-wrap d-flex justify-content-between">
                                            <h2 className="mb-2">{employeeLeavesData?.total_leaves !== undefined ? employeeLeavesData.total_leaves : ''}</h2>
                                            <div>
                                                <span className="badge bg-success rounded-pill p-2" style={{ fontWeight: '400' }}>
                                                    {remainingLeaves <= 0 ? "Sorry, you do not have any paid leaves. 😢" : "Enjoy your levaes 😊"}</span>
                                            </div>
                                        </div>
                                        <p className="text-info">Total Leaves</p>
                                    </div>
                                    <div className="grid-cols-1 d-grid gap">
                                        <button className="btn btn-soft-primary text-uppercase" onClick={() => navigate('/leaves')}>LEAVES SUMMARY</button>
                                    </div>
                                </div>
                            </div>
                            {Permissions.includes('View Employees') &&
                                <Col md="12" xl='12'>
                                    <div className="card" data-aos="fade-up" data-aos-delay="600">
                                        <div className=" flex-wrap card-header d-flex justify-content-between">
                                            <div className="col-lg-6 header-title">
                                                <h4 className="mb-2 card-title">
                                                    Holiday overview
                                                </h4>
                                                <p className="mb-0">
                                                    <svg
                                                        style={{ transform: 'rotate(180deg)' }}
                                                        className="me-2"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="#17904b"
                                                            d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                                                        />
                                                    </svg>
                                                    Upcoming Holidays
                                                </p>
                                            </div>
                                            <div className="col-lg-6 header-title d-flex align-items-center justify-content-end">
                                                <Link to='/holiday' className='btn btn-link btn-soft-primary'>
                                                    <span className='text-primary'>View Holidays</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {holidayData?.length > 0 ?
                                                holidayData.map((item, index) => (
                                                    index < 3 ?
                                                        <div className="mb-2 d-flex profile-media align-items-top" key={index}>
                                                            <div className="mt-1 profile-dots-pills border-primary"></div>
                                                            <div className="ms-4">
                                                                <h6 className="mb-1 ">{item.holiday_name}</h6>
                                                                <span className="mb-0">{moment(item.holiday_date).format('ll')}</span>
                                                            </div>
                                                        </div> : ''
                                                )) :
                                                <div className="mb-2 d-flex profile-media align-items-top">
                                                    <div className="mt-1 profile-dots-pills border-primary"></div>
                                                    <div className="ms-4">
                                                        <h6 className="mb-1 ">There is no Upcoming Holidays</h6>
                                                        <span className="mb-0"></span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
